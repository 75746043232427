<script setup>
import {useMetaInfoStore} from "@/stores/meta_info.js";
import {request} from "@/utils/axiosSetting.js";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
// import logo from "@/assets/logo.png";
import logo from "@/assets/logo_nobg.png";
// import logo from "@/assets/logo_chun.png";
import {useWindowSize} from "@vueuse/core";

const metaInfo = useMetaInfoStore();
const windowSize = useWindowSize();

const {t, locale} = useI18n();

const loading = ref(false);
const infoSource = ref(null);
const info = computed(() => {
    if (locale.value === "cn") {
        // 中文
        return {
            title: infoSource.value?.titleCN,
            banner: infoSource.value?.banner,
            url: infoSource.value?.url,
            bannerAddress: infoSource.value?.bannerAddressCN,
            address: infoSource.value?.address.map((item) => {
                return {
                    title: item?.titleCN,
                    phone: item?.phoneCN,
                    mailbox: item?.mailboxCN,
                    address: item?.addressCN,
                };
            }),
        };
    } else if (locale.value === "en") {
        // 英文
        return {
            title: infoSource.value?.titleEN,
            banner: infoSource.value?.banner,
            url: infoSource.value?.url,
            bannerAddress: infoSource.value?.bannerAddressEN,
            address: infoSource.value?.address.map((item) => {
                return {
                    title: item?.titleEN,
                    phone: item?.phoneEN,
                    mailbox: item?.mailboxEN,
                    address: item?.addressEN,
                };
            }),
        };
    }
});
const addressInfo = computed(() => {
    if (locale.value === "cn") {
        // 中文
        return {
            title: infoSource.value?.address?.[0]?.titleCN,
            phone: infoSource.value?.address?.[0]?.phoneCN,
            mailbox: infoSource.value?.address?.[0]?.mailboxCN,
            address: infoSource.value?.address?.[0]?.addressCN,
        };
    } else if (locale.value === "en") {
        // 英文
        return {
            title: infoSource.value?.address?.[0]?.titleEN,
            phone: infoSource.value?.address?.[0]?.phoneEN,
            mailbox: infoSource.value?.address?.[0]?.mailboxEN,
            address: infoSource.value?.address?.[0]?.addressEN,
        };
    }
});

const getData = async () => {
    const res = await request("/api/RestController.php", {
        method: "GET",
        params: {
            view: "contact",
        },
    });

    let data = res.data;

    data.banner = `${document.location.origin}${data.banner}`;

    infoSource.value = data;
};

onMounted(async () => {
    try {
        loading.value = true;
        await getData();
    } finally {
        loading.value = false;
    }
});
</script>

<template>
    <div class="flex items-center justify-center w-screen footer-container">
        <div class="footer-box w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
            <div class="contact-us-box">
                <div class="copy-rights">
                    <div class="logo-info">
                        <img :src="logo" alt="logo" class="logo" />
                        <span>{{ $t("home.footer") }}</span>
                    </div>
                    <div class="copy-info" v-show="windowSize.width.value > 768">
                        <span class="block sm:inline">{{ t("home.copy.rights") }}</span>
                        <span class="block sm:inline" v-html="metaInfo?.metaInfo?.beian"></span>
                    </div>
                </div>
                <div class="contact-us-info">
                    <div class="info-item">
                        <div class="info-item-title">{{ $t("contact.us.email.title") }}：</div>
                        <div class="info-item-text">
                            <p class="mailbox">
                                <span v-for="item in addressInfo?.mailbox?.split('；').join(';').split(';')" :key="item">{{ item }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copy-info" v-show="windowSize.width.value <= 768">
                <span class="block sm:inline">{{ t("home.copy.rights") }}</span>
                <span class="block sm:inline" v-html="metaInfo?.metaInfo?.beian"></span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.footer-container {
    background-color: rgba(54, 133, 126, 0.64);
    background-color: #bddcd6;
}
.footer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;

    .contact-us-box {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .copy-rights {
            box-sizing: border-box;
            padding-right: 0.5rem;
            display: flex;
            flex-direction: column;

            .logo-info {
                display: flex;
                align-items: center;

                > img {
                    width: 22px;
                    margin-right: 6px;
                }

                > span {
                    flex-shrink: 0;
                    font-size: 22px;
                    font-weight: 600;
                    color: #333;
                }
            }

            .copy-info {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                font-size: 12px;
                color: #aaa;
            }
        }

        .contact-us-info {
            display: flex;
            justify-content: flex-end;
            //max-width: 1200px;
            margin-bottom: 1rem;

            .info-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .info-item-title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333;
                    padding-bottom: 10px;
                }

                .info-item-text {
                    font-size: 14px;
                    font-weight: 600;
                    color: #555;

                    .mailbox {
                        display: inline-flex;
                        align-items: flex-start;
                        flex-direction: row;

                        > span {
                            margin-right: 20px;
                            margin-top: 4px;
                            text-align: left;
                            word-break: break-word;
                            word-wrap: break-word;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .copy-info {
        //margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 12px;
        color: #aaa;
    }

    @media (max-width: 1536px) {
        .contact-us-box {
            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;

            .copy-rights {
                flex-shrink: 1;
                display: flex;
                flex-direction: column;

                .copy-info {
                    display: flex;
                    //flex-direction: column;
                }
            }

            .contact-us-info {
                justify-content: flex-start;
                flex-shrink: 0;

                .info-item-title {
                    //margin-top: 20px;
                }

                .info-item {
                    .info-item-text {
                        .mailbox {
                            > span {
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1280px) {
        .contact-us-box {
            //flex-direction: column;
            //justify-content: flex-start;
            // align-items: center;

            .copy-rights {
                display: flex;
                flex-direction: column;
                //align-items: center;
            }

            .contact-us-info {
                .info-item {
                    .info-item-text {
                        .mailbox {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .contact-us-box {
            .copy-rights {
                display: flex;

                .logo-info {
                    margin-right: 1rem;
                }

                .copy-info {
                    margin-top: 0;
                    display: flex;
                    flex-direction: row;
                }
            }

            .contact-us-info {
                //margin-top: 20px;
                //width: 100%;
                justify-content: flex-start;
            }
        }
    }

    @media (max-width: 768px) {
        .contact-us-box {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .copy-rights {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .logo-info {
                    > span {
                        flex-shrink: 1;
                    }
                }

                .copy-info {
                    flex-direction: column;
                }
            }

            .contact-us-info {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;

                .info-item {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;

                    .info-item-title {
                        font-size: 14px;
                        color: #333;
                        padding-bottom: 10px;
                        margin-top: 20px;
                    }

                    .info-item-text {
                        font-size: 12px;
                        color: #888;

                        .mailbox {
                            display: inline-flex;
                            align-items: flex-start;
                            flex-direction: column;

                            > span {
                                margin-top: 4px;
                                text-align: left;
                                word-break: break-word;
                                word-wrap: break-word;
                            }
                        }
                    }
                }
            }
        }

        .copy-info {
            flex-direction: column;
        }
    }
}
</style>